export const option = {
    title:'二手房房源列表',
    titleSize:'h3',
    card: true,
    border: true,
    header: false,
    stripe: false, // 显示斑马纹
    showHeader: true, // 显示表头
    index: false, // 显示索引
    size: 'small',
    selection: false, // 显示多选框
    page: true, // 显示分页
    align:'center',
    menuAlign:'center',
    menuType: 'text',
    menuWidth: 300,
    editBtn: false,
    delBtn: false,
    column: [
        {
            label: '二手房房源号',
            prop: 'code',
            width: 200
        },
        {
            label: '房源标题',
            prop: 'title',
            width: 200
        },
        {
            label: '城市',
            prop: 'cityName',
            width: 150
        },
        {
            label: '区县',
            prop: 'countyName',
            width: 150
        },
        {
            label: '商圈',
            prop: 'businessName',
            width: 150
        },
        {
            label: '楼盘名称',
            prop: 'buildName',
            width: 150
        },
        {
            label: '售价(万元)',
            prop: 'sellPrice',
            width: 150
        },
        {
            label: '户型',
            prop: 'bedroom',
            formatter: (row) => {
                return row.bedroom + '室' + row.office + '厅'
            },
            width: 150
        },
        {
            label: '建筑面积(㎡)',
            prop: 'floorage',
            width: 150
        },
        {
            label: '标签',
            prop: 'labelName',
            width: 200
        },
        {
            label: '在售状态',
            prop: 'isSell',
            formatter: function(row, column, cellValue){
                return cellValue === 1 ? '在售' : cellValue === 2 ? '已售' : '/';
            },
            width: 150
        },
        {
            label: '首页推荐',
            prop: 'isRecommend',
            width: 150
        },
        {
            label: '录入角色',
            prop: 'entryRole',
            formatter: function(row, column, cellValue){
                return cellValue === 1 ? '平台' : cellValue === 2 ? '业主' : cellValue === 3 ? '认证经纪人' : cellValue === 4 ? '八爪鱼抓取' : '/';
            },
            width: 150
        },
        {
            label: '录入人名称',
            prop: 'entryName',
            formatter: function(row, column, cellValue){
                return cellValue ? cellValue : '/';
            },
            width: 150
        },
        {
            label: '上架状态',
            prop: 'state',
            // formatter: function(row, column, cellValue){
            //     return cellValue === 1 ? '上架' : cellValue === 2 ? '下架' : cellValue === 3 ? '待发布' : '/';
            // },
            width: 150
        },
        {
            label: '交易状态',
            prop: 'tradeState',
            formatter: function(row, column, cellValue){
                return cellValue === 1 ? '未发起' : cellValue === 2 ? '正在交易' : cellValue === 3 ? '交易完成' : '/';
            },
            width: 150
        },
        {
            label: '成交时间',
            prop: 'tradeTime',
            formatter: function(row, column, cellValue){
                return cellValue ? cellValue : '/';
            },
            width: 150
        },
        {
            label: '成交金额(万元)',
            prop: 'tradePrice',
            formatter: function(row, column, cellValue){
                return cellValue ? cellValue : '/';
            },
            width: 150
        },
        {
            label: '是否置顶',
            prop: 'isTop',
            width: 150
        },
        {
            label: '是否签订挂牌委托协议',
            prop: 'isSign',
            formatter: function(row, column, cellValue){
                return cellValue === 1 ? '是' : cellValue === 2 ? '否' : '/';
            },
            width: 200
        },
        {
            label: '是否有VR链接',
            prop: 'isVr',
            formatter: function(row, column, cellValue){
                return cellValue === 1 ? '是' : cellValue === 2 ? '否' : '/';
            },
            width: 150
        },
        {
            label: '是否安排经纪人',
            prop: 'isArrangeBroker',
            formatter: function(row, column, cellValue){
                return cellValue === 1 ? '是' : cellValue === 2 ? '否' : '/';
            },
            width: 150
        },
        {
            label: '经纪人',
            prop: 'brokerName',
            formatter: function(row, column, cellValue){
                return cellValue ? cellValue : '/';
            },
            width: 150
        },
        {
            label: '经纪人手机号',
            prop: 'brokerPhone',
            formatter: function(row, column, cellValue){
                return cellValue ? cellValue : '/';
            },
            width: 150
        },
    ]
}
export const brokerOption = {
    title:'',
    titleSize:'h3',
    card: true,
    border: true,
    header: false,
    stripe: false, // 显示斑马纹
    showHeader: true, // 显示表头
    index: false, // 显示索引
    size: 'small',
    selection: false, // 显示多选框
    page: true, // 显示分页
    align:'center',
    menuAlign:'center',
    menuType: 'text',
    editBtn: false,
    delBtn: false,
    column: [
        {
            label: '用户昵称',
            prop: 'nickname'
        },
        {
            label: '用户手机号',
            prop: 'phone'
        },
        {
            label: '用户id',
            prop: 'code'
        },
        {
            label: '用户姓名',
            prop: 'username'
        }
    ]
}